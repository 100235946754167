import React from 'react'
import { css } from '@emotion/core'
import { Container } from 'react-bootstrap'
/* eslint no-unused-vars: 0 */
import style from './styles.css'

export default () => (
  <div className="serviceLevel">
    <Container
      css={css`
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 30px 3px rgba(235, 235, 235, 0.5);
        border-radius: 27px;
        font-family: PingFangSC-Regular, PingFang SC;
      `}
    >
      <div className="serviceLevel-content">
        <div
          css={css`
            font-weight: bold;
            text-align: center;
            margin: 0 auto;
          `}
          className="serviceLevel-content-title"
        >
          WF Cloud API Gateway服务等级协议
          <br />
          <br />
        </div>
        <p><strong>WF&nbsp;Cloud&nbsp;API&nbsp;Gateway</strong><strong>服务等级协议</strong></p>
<p>本服务等级协议（Service Level Agreement，简称 &ldquo;SLA&rdquo;）规定了WF Cloud API Gateway向客户提供的API网关的服务可用性等级指标及赔偿方案。</p>
<p>&nbsp;</p>
<ol>
<li><strong>定义</strong></li>
</ol>
<p>服务周期：API 网关服务可用性按服务周期统计，一个服务周期为一个自然月，如客户使用 API 网关不满一个月则以当月使用API网关的累计使用时间作为一个服务周期。</p>
<p>有效请求：API网关接收到的所有请求，视为有效请求。</p>
<p>失败请求：由于 API 网关原因造成的API调用失败，则视为失败请求但不包括以下情况的调用失败：</p>
<p>(1)因用户API配置问题导致的API调用失败；</p>
<p>(2)因用户后端服务异常、应答超时、无法连接、网络不可达等原因导致的API调用失败；</p>
<p>(3)当用户后端服务需要通过公网访问时，因网络传输问题导致的API调用失败；</p>
<p>(4)直接调用API分组的公网二级域名时出现的API调用失败；</p>
<p>(5)客户的应用程序受到黑客攻击而导致被API网关限制的请求。</p>
<p>当出现API网关故障无法通过获得失败请求数时，将通过计算前7个自然日用户每分钟请求数的平均值，用该平均值乘以故障时间，从而计算出该情况下的失败请求数。</p>
<p>每5分钟错误率：以5分钟为单位按照如下方式计算错误率：</p>
<p>每5分钟错误率=每5分钟失败请求数/每5分钟有效总请求数x100%</p>
<p>月度服务费用：客户在一个自然月中就API网关服务所支付的服务费用总额。以代金券结算不计入月服务费用。</p>
<p>&nbsp;</p>
<ol start="2">
<li><strong>服务可用性</strong></li>
</ol>
<p><strong>2.1&nbsp;服务可用性计算方式</strong></p>
<p>API网关的服务可用性按服务周期统计，通过计算服务周期内每5分钟错误率的平均值，从而计算得出服务可用性，即：</p>
<p>服务可用性=（1-服务周期内&Sigma;每5分钟错误率/服务周期内5分钟总个数）x1</p>
<p>（注：服务周期内5分钟总个数=12 x 24 x 该服务周期的天数）</p>
<p>&nbsp;</p>
<p><strong>2.2&nbsp;服务可以用性承诺</strong></p>
<p>API网关的实例类型不做区分，WF Cloud API Gateway承诺一个服务周期内的服务可用性不低于99.99%。<br />如API网关未达到上述可用性承诺，客户可以根据本协议第3条约定获得赔偿。赔偿范围不包括以下原因所导致的服务不可用：</p>
<p>（1）WF Cloud API Gateway预先通知用户后进行系统维护所引起的，包括割接、维修、升级和模拟故障演练；</p>
<p>（2）任何WF Cloud API Gateway所属设备以外的网络、设备故障或配置调整引起的；</p>
<p>（3）用户的应用程序或数据信息受到黑客攻击而引起的；</p>
<p>（4）用户维护不当或保密不当致使数据、口令、密码等丢失或泄漏所引起的；</p>
<p>（5）用户的疏忽或由用户授权的操作所引起的；</p>
<p>（6）不可抗力以及意外事件引起的；</p>
<p>（7）其他非WF Cloud API Gateway原因所造成的不可用。</p>
<ol start="3">
<li><strong>赔偿方案</strong></li>
</ol>
<p><strong>3.1赔偿标准</strong></p>
<p>根据客户某一WF Cloud API Gateway账号下API网关的月度服务可用性，按照下表中的标准计算赔偿金额，赔偿方式仅限于用于购买API网关产品的代金券，且赔偿总额不超过未达到服务可用性承诺当月客户就该账号下API网关所支付的月度服务费用（不含用代金券抵扣的费用）。</p>
<table>
<tbody>
<tr>
<td width="343">
<p  css={css`
               margin-bottom: 0.54rem;
              margin-top: 0.54rem;
          `}><strong>服务可用性</strong></p>
</td>
<td width="217">
<p  css={css`
               margin-bottom: 0.54rem;
              margin-top: 0.54rem;
          `}><strong>赔偿代金券金额</strong></p>
</td>
</tr>
<tr>
<td width="343">
<p  css={css`
               margin-bottom: 0.54rem;
              margin-top: 0.54rem;
          `}>低于 99.90% 但等于或高于 99.00%</p>
</td>
<td width="217">
<p  css={css`
               margin-bottom: 0.54rem;
              margin-top: 0.54rem;
          `}>月度服务费用的 15%</p>
</td>
</tr>
<tr>
<td width="343">
<p  css={css`
               margin-bottom: 0.54rem;
              margin-top: 0.54rem;
          `}>低于 99.00% 但等于或高于 95.00%</p>
</td>
<td width="217">
<p  css={css`
               margin-bottom: 0.54rem;
              margin-top: 0.54rem;
          `}>月度服务费用的 30%</p>
</td>
</tr>
<tr>
<td width="343">
<p  css={css`
               margin-bottom: 0.54rem;
              margin-top: 0.54rem;
          `}>低于 95.00%</p>
</td>
<td width="217">
<p  css={css`
              margin-bottom: 0.54rem;
              margin-top: 0.54rem;
          `}>月度服务费用的 100%</p>
</td>
</tr>
</tbody>
</table>
<p><br />客户可以在每月第五（5）个工作日后对上个月没有达到可用性的实例提出赔偿申请。&nbsp;</p>
<p>&nbsp;</p>
<p><strong>3.2赔偿申请时限</strong></p>
<p>赔偿申请必须限于在API网关没有达到可用性的相关月份结束后两（2）个月内提出。超出申请时限的赔偿申请将不被受理。</p>
<p>&nbsp;</p>
<p><strong>4.其他</strong></p>
<p>WF Cloud API Gateway有权对本SLA条款作出修改。如本SLA条款有任何修改，WF Cloud API Gateway将提前 30 天以网站公示或发送邮件的方式通知您。如您不同意WF Cloud API Gateway对SLA所做的修改，您有权停止使用API网关服务，如您继续使用API网关服务，则视为您接受修改后的SLA。</p>
<p>&nbsp;</p>
<p>&nbsp;</p>  </div>
    </Container>
  </div>
)
